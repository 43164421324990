import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/shared/layout';
import SEO from '../components/SEO/SEO';
import ListingPaginated from '../components/Search/ListingPaginated';

const SearchTemplate = ({ data, pageContext }) => {
  const metaTitle = pageContext.metaTitle;
  const metaDesc = pageContext.metaDesc;
  const pathname = pageContext.pathname;
  const pageSize = pageContext.pageSize;
  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={pathname}
      />
      <ListingPaginated pageSize={pageSize}/>
    </Layout>
  );
};

SearchTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object
};

export default SearchTemplate;
