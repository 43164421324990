import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { breakpoints, color, wrapper } from '../../utils/style';
import { domain } from '../Auth/Config.js';
import withLocation from '../shared/withLocation';

const Wrapper = styled('div')`
 ${wrapper}
 p {
   margin-bottom: 1rem;
 }
 h1 {
   margin-top: 10px;
 }
`;

const Wrap = styled('div')`
  text-align: left;
  .search-items-list {
    list-style: none;
    padding-left: 0;
  }
  li {
    margin: 0 0 30px;
  }
  .h3 {
    margin-bottom: 7px;
  }
  .p {
    font-size: 14px;
  }
  a:hover, a:active {
    text-decoration: none;
    .h3 {
      color: ${color.red};
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    li {
      margin-bottom: 15px;
    }
    .h3 {
      margin: 0;
      line-height: 1.2;
    }
    .p {
      display: none;
    }
  }
`;


const PaginationHolder = styled('div')`
  padding: 10px 0;
  text-align: center;
  margin-bottom: 20px;
  div, a, span {
    display: inline-block;
    vertical-align:top;
  }
    
  .pagination {
    margin: 6px 3px;
    padding: 3px 7px;
    background-color: ${color.midGrey};
    color: ${color.white};
    min-width:32px;
    height: 24px;
    line-height: 18px;
    text-align:center;
    border:none;
    border-radius: 5px;
  }
  .pagination:hover, .pagination:active, .pagination-span{
    background-color: ${color.red};
    color: ${color.white};
    text-decoration:none;
    line-height: 18px;
  }
    
  .previousLink .pagination, .nextLink .pagination {
    height:24px;
    width:32px;
    position:relative;
    display:inline-block;
    border:none;
  }
  .previousLink .pagination::before {
    content:"";
    position:absolute;
    right:13px;
    top:4px;
    width:0;
    height:0;
    border:8px solid transparent;
    border-right-color:${color.black};
  } 
  .nextLink .pagination::before {
    content:"";
    position:absolute;
    top:4px;
    left:13px;
    width:0;
    height:0;
    border:8px solid transparent;
    border-left-color:${color.black};
  }
  .lastLink .pagination, .firstLink .pagination {
    width: 40px;
  }
  .previousLink .pagination::after {
    content:"";
    position:absolute;
    left:3px;
    top:4px;
    width:0;
    height:0;
    border:8px solid transparent;
    border-right-color:${color.black};
  }
  .lastLink .pagination::after {
    content:"";
    position:absolute;
    top:4px;
    right:3px;
    width:0;
    height:0;
    border:8px solid transparent;
    border-left-color:${color.black};
  }
`;

const listingUrl = (page, pageSize, search) => {
  let query = search;
  let searchtext = '';
  if (query && query.q) {
    searchtext = query.q;
  }
  return encodeURI(domain + `/wp-json/gclubsearch/v1/search?s=${searchtext}&per_page=${pageSize}&page=${page}`);
};

const useFetch = url => {
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(null);
  const [totalPages, setTotalPages] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(url);
      isLoading = true;
      const json = await response.json();
      const dataHeaders = response.headers;
      setData(json);
      setTotal(dataHeaders.get('X-WP-Total'));
      setTotalPages(dataHeaders.get('X-WP-TotalPages'));
    };
    fetchData();
  }, [url]);
  let result = { items: data, total: total, totalPages: totalPages };
  return result;
};

const createShortPagination = (pagecount, index, pageUpdate) => {
  let pagesAroundLimit = 4;
  let hasPrevPageButton = false;
  let hasNextPageButton = false;
  let pages = [];
  for (let i = 1; i <= pagecount; i++) {
    let page;
    if (i < (index - pagesAroundLimit)) {
      if (!hasPrevPageButton) {
        page = <div className='previousLink' key={'prev-dots'}>
          <button aria-label={'Prev'} onClick={() => pageUpdate(index - 1)} className='pagination'/>
        </div>;
        pages.push(page);
        hasPrevPageButton = true;
      }
      continue;
    }
    if (i > (index + pagesAroundLimit)) {
      hasNextPageButton = true;
      continue;
    }
    if (i !== +index) {
      page = <button key={i} onClick={() => pageUpdate(i)} className='pagination'> {i} </button>;
    } else {
      page = <span key={i} className='pagination pagination-span'>{i}</span>;
    }
    pages.push(page);
  }
  if (hasNextPageButton) {
    let page;
    page = <div className='nextLink' key={'next-dots'}>
      <button aria-label={'Next'} onClick={() => pageUpdate(index + 1)} className='pagination'/>
    </div>;
    pages.push(page);
  }
  return pages;
};

const PageNumber = styled('div')`
  font-size: 13px;
  margin: 10px 0;
  color: ${color.midGrey};
`;

let isLoading = true;

const ListingPaginated = ({ pageSize, search }) => {
  const [page, setPage] = useState(0);
  const url = listingUrl((page > 1) ? page : 1, pageSize, search);
  const result = useFetch(url);
  const items = result.items;
  const pageCount = result.totalPages;
  const pageUpdate = (i) => {
    if (i !== +page) {
      isLoading = false;
      setPage(i);
    }
  };

  return (
    <Wrapper>
      {(Array.isArray(items) && items.length > 0) &&
      <div>
        <h1>ผลการค้นหา</h1>
        <PageNumber>หน้า {(page > 1) ? page : 1}</PageNumber>
      </div>
      }
      <Wrap>
        {(isLoading && Array.isArray(items)) ? <ul className='search-items-list' id={'search-items-wrapper'}>
          {items.map((item) => <li key={item.id + 'search-item' + Math.random()}>
            <Link className="article" to={'/' + item.slug.replace('-en', '') + '/'}>
              <div className="h3" dangerouslySetInnerHTML={{ __html: item.title.replace(' EN', '') }}/>
              <div className="p" dangerouslySetInnerHTML={{ __html: item.excerpt }}/>
            </Link></li>
          )}
        </ul> : <div>กำลังโหลด...</div>}
        {Array.isArray(items) && !items.length &&
        <div>
          <h1>ไม่พบข้อมูลการค้นหา</h1>
          <Link className="button" to="/">กลับไปที่หน้าแรก</Link>
        </div>
        }
        {pageCount > 2 &&
        <PaginationHolder>
          <div className='pageNumbers'>
            <div className='firstLink previousLink'>
              <button aria-label={'First/Prev'} onClick={() => pageUpdate(1)} className='pagination'/>
            </div>
            <div className="numberedPages">
              {createShortPagination(pageCount, (page > 1) ? page : 1, pageUpdate)}
            </div>
            <div className='lastLink nextLink'>
              <button aria-label={'Next/Last'} onClick={() => pageUpdate(pageCount)} className='pagination'/>
            </div>
          </div>
        </PaginationHolder>
        }
      </Wrap>
    </Wrapper>
  );
};

ListingPaginated.propTypes = {
  data: PropTypes.object,
  edges: PropTypes.array,
  search: PropTypes.object,
};

export default withLocation(ListingPaginated);
